.brandLogo {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    transition: all 0.1s ease-in-out;
    margin-top: 0;
    padding-top: 0;
}

.brandLogo img {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
    background-color: var(--bs-primary);
    transition: all 0.1s ease;
    margin-top: 0;
}

.topDiv {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sideSection {
    flex: 1;
    display: flex;
    justify-content: center;
}

.brandText {
    color: var(--bs-primary) !important;
    font-family: "Georgia", serif;
    font-size: 1.2rem !important;
}

.searchForm {
    display: flex;
    align-items: center;
}

.searchButton {
    width: 36px;
    height: 36px;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bs-primary);
}

.searchControl {
    border: 1px solid rgb(206, 206, 206) !important;
    background-color: white !important;
    height: 36px;
    color: black !important;
    transition: border 0.3s ease, box-shadow 0.3s ease;
}

.searchControl::placeholder {
    color: grey !important;
}

.searchControl:focus {
    border-color: rgb(75, 75, 75) !important;
    background-color: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.searchButtonSmall {
    background: linear-gradient(to bottom, #500000 0%, #500000 77%, #3c0000 100%);
}

.searchButtonSmall:hover {
    background: #3C0000;
}

.mouseDown img {
    background: #3C0000;
}

.bottomDiv {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 60px;
    background: linear-gradient(to bottom, #500000 0%, #500000 77%, #3c0000 100%);
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.navContainer {
    flex: 1;
    display: flex;
    align-items: stretch;
}

.leftNav {
    justify-content: flex-end;
}

.rightNav {
    justify-content: flex-start;
}

.navLink {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #500000 0%, #500000 77%, #3c0000 100%);
    font-size: 1.25rem;
    color: white;
    text-decoration: none;
    padding: 0 1rem 0 1rem;
}

.navLink:hover {
    background: #3C0000;
    color: white;
}

.active {
    background: #3C0000;
    color: white !important;
}

.navLink+.navLink {
    margin-left: 0;
}

.navbar {
    padding: 0 !important;
    align-items: center;
}

.navbarToggle {
    height: 60px;
}

@media (max-width: 1024px) {
    .topDiv {
        display: none;
    }

    .brandLogo img {
        box-shadow: none;
        background: linear-gradient(to bottom, #500000 0%, #500000 77%, #3c0000 100%);
    }

    .bottomDiv {
        justify-content: space-between;
    }

    .navLink {
        font-size: 1rem;
        padding: .5rem 0;
        text-transform: none;
        background: var(--bs-primary);
    }
}