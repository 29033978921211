.footerContainer {
    background-color: #333333;
    color: white;
}

.legalContainer {
    margin-top: 10px;
    font-size: 14px;
}

.legalContainer a {
    margin: 0 10px;
    color: white;
    text-decoration: none;
}

.socialList {
    display: flex;
    flex-direction: row;
    justify-content: center;
}