.bannerSpacer {
    height: 300px;
    background-color: black;
}

.partnerContainer,
.sponsorContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.bannerPlaceholder {
    height: 640px;
    background-color: black;
}

.partnerLink img {
    max-height: 75px;
    width: auto;
    max-width: 125px;
}

.partnerDiv {
    display: flex;
    align-items: center;
    height: 70px;
}

.followUsWrapper {
    background-color: gray;
}

.socialsColumn,
.twitterColumn {
    height: 400px;
}

.socialList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: 0 auto;
}

.hidden {
    visibility: hidden;
    position: absolute;
}

@media (max-width: 1024px) {
    .bannerSpacer {
        height: 100px;
    }
}