.discordIcon:hover>svg {
    color: #7289da;
}

.twitterIcon:hover>svg {
    color: #1da1f2;
}

.twitchIcon:hover>svg {
    color: #9146ff;
}

.tiktokIcon:hover>svg {
    color: #000000;
}

.instagramIcon:hover>svg {
    color: #E1306C;
}

.facebookIcon:hover>svg {
    color: #3b5998;
}

.linkedInIcon:hover>svg {
    color: #0077B5;
}

.youtubeIcon:hover>svg {
    color: #FF0000;
}

.socialIcon {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin: 0 8px;
    color: white;
    text-decoration: none;
}

.socialIcon>svg {
    transition: color 0.2s ease;
    margin-right: 8px;
}