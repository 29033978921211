.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27 fill=%27none%27 stroke=%27%23ffffff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.navbar-toggler, .navbar-toggler:focus:not(:focus-visible) {
    border: none;
    box-shadow: none;
}

.navbar-toggler:hover, .navbar-toggler:not(.collapsed) {
    background-color: #3c0000;
}