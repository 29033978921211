.duesCard {
    background-color: #f3f3f3;
    border: 2px solid #eaeaea;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.10);
    transition: transform 0.3s, box-shadow 0.3s;
    border-radius: 5px;
    padding: 20px;
}

.duesCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.cardTitle {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 1.5rem;
    border-bottom: 2px solid #7A0000;
    display: inline-block;
    padding-bottom: 5px;
}