.accordion-button {
    background-color: #500000;
    color: #FFFFFF;
}

.accordion-button:hover {
    background-color: #6A0015;
}

.accordion-button:not(.collapsed) {
    background-color: #4D0000;
}

.accordion-button:not(.collapsed):hover {
    background-color: #590000;
}

.accordion-button:not(.collapsed)::after,
.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed),
.accordion-button.collapsed {
    color: #FFFFFF;
}

.accordion-item>.accordion-collapse .accordion-body {
    background-color: #3C0000;
}

.accordion-body {
    color: white;
}

.accordion-button,
.accordion-collapse,
.accordion-body,
.accordion-item,
.accordion-button:focus,
.accordion-collapse:focus,
.accordion-body:focus,
.accordion-item:focus {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}