.leadershipCard {
    background-color: #590000;
    color: white;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 160px;
}

.leadershipCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}

.missionVisionCards {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 300px;
    padding: 20px;
    margin: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.missionVisionCards:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}

.lightGreyBackground {
    background-color: #eaeaea;
    color: var(--bs-primary);
}

.maroonBackground {
    background-color: var(--bs-primary);
    color: white;
}